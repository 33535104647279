export const pdfStyles = {
  page: {
    // padding: 20,
    fontFamily: "Helvetica",
    position: "relative",
    // backgroundColor: "#1C214D",
  },
  whitecontainer: {
    padding: "20",
  },
  schoolLogoSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  schoolLogo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    marginTop: "50"
  },
  schoolLogo_img: {
    width: 40,
    height: 40,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  schoolDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  schoolName: {
    fontSize: "14",
    fontWeight: "bold",
  },

  schoolAddress: {
    fontSize: 8,
  },
  qrContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: 5,
  },
  qrCodeImage: {
    width: 120,
    height: 120,
    marginVertical: 10,
  },
  content: {
    marginTop: 5,
    padding: 5,
    fontSize: 10,
    lineHeight: 1,
  },
  title: {
    fontSize: 10,
    fontWeight: "bold",
    marginTop: 7,
    marginBottom: 3,
    color: "#34495E",
  },
  logo: {
    width: 150,
    height: 100,
    position: "absolute",
    right: 0,
    top: 0,
  },
  registrationUrl: {
    fontSize: 9,
    width: 300,
    height: 70,
    backgroundColor: "#c6f7f5",
    padding: 10,
    borderRadius: 5,
  },
  paragraph: {
    fontSize: 8,
    lineHeight: 1.5,
    textAlign: "left", // Optional: adjust based on your layout
    color: "rgba(52, 73, 94, 0.8)",
  },
  boldText: {
    fontWeight: "bold",
    color: "#34495E",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#2D2E83", // Blue background color
    color: "#fff", // White text color
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  footerImage: {
    width: 50,
    height: 30,
  },
  footerIcon: {
    width: 12,
    height: 12,
    marginRight: 5,
    backgroundColor: "#90f5eb",
    borderRadius: 50,
  },
  footerContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  footerContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  footerText: {
    fontSize: 10,
    color: "#fff",
  },
  footerdownload: {
    width: 150,
    height: 130,
    marginBottom: 30,
    marginRight: 50,
  },
  footerQrScanner: {
    position: "absolute",
    top: -60,
    right: 10,
    width: 100,
    height: 120,
  },
  schoolLogoImage: {
    width: 80,
    height: 80,
    borderRadius: "50%",
  },
  qrCode: {
    width: 50,
    height: 50,
  },
  footer2: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#6671f2",
    color: "#fff",
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 30,
  },
  paragraph2: {
    fontSize: 7,
    lineHeight: 1.5,
    color: "white",
    paddingBottom: 30,
    marginRight: 40,
  },
  boldText2: {
    fontWeight: "bold",
    color: "white",
    fontSize: 8,
  },
};
