import Header from "../../landingPage/Header";
import Footer from "../../landingPage/Footer";
import { Outlet } from "react-router-dom";

const SchoolDashboardLayout = () => {
  return (
    <div>
      <Header placement="dashboard" dashboard="school" />
      <div className="mt-40">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default SchoolDashboardLayout;
