import React, { useRef, useState } from "react";
import { loginFormFields } from "../constants/loginFormFields";
import { Button, TextInput } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "../../../assets/js/validation";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  auth,
  parentsCollectionRef,
  schoolCollectionRef,
} from "../../../config/firebaseConfig";
import { getDocs, query, where } from "firebase/firestore";

const UserLogin = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUserData = async (userId) => {
    let userData = [];
    try {
      const schoolSnapshot = await getDocs(
        query(schoolCollectionRef, where("uid", "==", userId))
      );
      schoolSnapshot.docs.map((doc) =>
        userData.push({
          id: doc.id,
          ...doc.data(),
        })
      );

      // Fetch parents
      const parentsSnapshot = await getDocs(
        parentsCollectionRef,
        where("uid", "==", userId)
      );

      parentsSnapshot.docs.map((doc) =>
        userData?.push({ id: doc.id, ...doc.data() })
      );

      return userData[0];
    } catch (error) {
      throw ("Error fetching data:", error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    const formData = e.target;
    const email = formData.email.value;
    const password = formData.password.value;

    if (!email) {
      setError((prev) => ({
        ...prev,
        email: "Please enter a valid email id",
      }));
    }
    if (!password) {
      setError((prev) => ({
        ...prev,
        password: "Please enter a valid password",
      }));
    }
    if (!validateEmail(email)) {
      setError((prev) => ({
        ...prev,
        email: "Please enter a valid email id",
      }));
    }

    if (!email || !password) return;

    setLoading(true);
    try {
      // 1. Login with email and password
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        // 2. fetch user data with user id
        const userData = await getUserData(user.uid);
        // 3. find the account type and navigate to school or parent dashboard accordingly
        if (userData) {
          navigate(
            userData?.accountType === "school"
              ? "/schoolDashboard"
              : userData?.accountType === "parent"
                ? "/parentDashboard"
                : "/"
          );
        } else {
          setError((prev) => ({
            ...prev,
            other: "User not found",
          }));
          return;
        }
      }
    } catch (error) {
      setError((prev) => ({
        ...prev,
        other:
          error.code === "auth/user-not-found"
            ? "User not found, Enter a valid email id."
            : error.code === "auth/wrong-password"
              ? "Incorrect password."
              : error.message,
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-data step-1">
      <h3>Sign In to your Account</h3>

      <form onSubmit={handleLogin} className="w-full mt-10" ref={formRef}>
        {loginFormFields?.map(
          ({ name, label, type, required, placeholder }) => {
            return (
              <div key={name} className={`form-group`}>
                <label htmlFor={name}>
                  {label}
                  {required && <span> * </span>}
                </label>
                <TextInput
                  type={type}
                  placeholderText={placeholder}
                  inputName={name}
                  error={error?.[name]}
                />
                {name === "password" && (
                  <div className="text-right">
                    <Link
                      to="forgotPassword"
                      className="text-[#009DFF] hover:text-[#009DFF] font-bold"
                    >
                      Forgot Password
                    </Link>
                  </div>
                )}
              </div>
            );
          }
        )}
        {error?.other && (
          <div className="text-[#f00] text-xs mb-1 ml-4">{error?.other}</div>
        )}

        <div className="flex flex-col gap-4 col-md-12">
          <div className="">
            <Button
              version="secondary"
              text="Sign In"
              type="submit"
              loading={loading}
            />
          </div>
          <div className="text-lg flex gap-2">
            <p className="font-medium">Don’t have an account?</p>
            <Link
              to="/register"
              className="font-bold hover:underline cursor-pointer text-[#009DFF] hover:text-[#009DFF]"
            >
              Register Now
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserLogin;
