import React, { useEffect, useRef, useState } from "react";
import { subjectsOptions } from "../../auth/constants/options";
import { Button, Checkbox } from "../../../components";
import Cart from "../../auth/onboarding/Cart";
import envConfig from "../../../config/envConfig";
import Step1Instructions from "../../auth/onboarding/Step1Instructions";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { auth, db } from "../../../config/firebaseConfig";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ImSpinner2 } from "react-icons/im";

const AddSubjects = ({ studentDetails }) => {
  const [subjects, setSubjects] = useState();
  const [addedSubjects, setAddedSubjects] = useState([]);
  const [amount, setAmount] = useState(0);
  const [country, setCountry] = useState("IN");
  const [cartPositon, setCartPosition] = useState("absolute top-0 -right-60");
  const [acknowledgeChecked, setAcknowledgeChecked] = useState(false);
  const cartRef = useRef();
  const subjectRef = useRef();
  const acknowledgeErrorRef = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(
          `https://ipinfo.io/json?token=${envConfig?.ipInfoKey}`
        );
        const data = await response.json();
        const country = data.country;
        setCountry(country);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
        setCountry("IN");
      }
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    if (studentDetails) {
      setSubjects(studentDetails?.subjects?.map((sub) => sub.subject));
    }
  }, [studentDetails, setSubjects]);

  const handleSaveAndPay = async (e) => {
    e.preventDefault();
    setError(null);

    // 1. validation
    if (!acknowledgeChecked) {
      setError((prev) => ({
        ...prev,
        acknowledgement: "Please check the acknowledge",
      }));
      acknowledgeErrorRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (addedSubjects?.length === 0) {
      setError((prev) => ({ ...prev, subjects: "Please select subjects" }));
      subjectRef?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    // 2. update the student doc
    const subjectsAdded = addedSubjects?.map((subject) => {
      const examCode = `P${subject?.slice(0, 1)}O-${uuidv4().substring(
        0,
        5
      )}`?.toUpperCase();
      return {
        subject: subject,
        examCode: examCode,
      };
    });

    const studentData = {
      subjects: [...studentDetails?.subjects, ...subjectsAdded],
      updatedAt: serverTimestamp(),
    };

    console.log(studentData);
    setLoading((prev) => ({ ...prev, submit: true }));
    try {
      const studentDocRef = doc(
        db,
        "participants",
        studentDetails?.enrollmentNo
      );
      // update to firebase document
      await updateDoc(studentDocRef, studentData);
      console.log("Student data updated successfully");

      // 3. open payment gateway
      const paymentFor = [
        {
          participantId: studentDetails?.enrollmentNo,
          subjects: addedSubjects,
        },
      ];
      await handlePayment(amount, paymentFor);
    } catch (error) {
      console.log(error);
      setError((prev) => ({
        ...prev,
        submit: error.code,
      }));
    } finally {
      setLoading((prev) => ({ ...prev, submit: false }));
    }
  };
  const handlePayment = async (amount, paymentFor) => {
    const backendUrl = envConfig.backendUrl;
    const {
      data: { key },
    } = await axios.get(`${backendUrl}/api/getkey`);
    const {
      data: { order },
    } = await axios.post(`${backendUrl}/api/checkout`, {
      amount,
      parentId: studentDetails?.parent?.id,
      paymentFor: paymentFor,
      currency: country === "IN" ? "INR" : "USD",
      redirectUrl: `${envConfig.appUrl}parentDashboard/${studentDetails?.enrollmentNo}`,
    });
    const options = {
      key,
      amount: order.amount,
      currency: country === "IN" ? "INR" : "USD",
      name: envConfig.appFullName,
      description: envConfig.appFullName,
      image: "/img/appLogo.png",
      order_id: order.id,
      callback_url: `${backendUrl}/api/paymentVerification`,
      prefill: {
        name: studentDetails?.parent?.name,
        email: studentDetails?.parent?.email,
        contact: studentDetails?.parent?.phone,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#E74C3C",
      },
    };

    console.log(options);
    const razor = new window.Razorpay(options);
    razor.open();
    setLoading((prev) => ({ ...prev, module: true }));
  };

  return (
    <div className="relative onboarding-step max-w-[990px] mt-0 mb-[50px] mx-auto !space-y-6">
      {loading?.module && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-black/20 backdrop-blur-sm flex items-center justify-center">
          <div className=" p-20 py-10 bg-white shadow-md rounded-xl text-gray-500 flex flex-col items-center gap-4">
            <ImSpinner2 className="animate-spin w-8 h-8" />
            <p className="text-2xl font-bold ">Please Wait</p>
          </div>
        </div>
      )}
      <div
        className={`summary-card for-mobile w-[450px] z-40 right-[10%] -top-10 h-full absolute `}
      >
        <div
          className={`summary-card ${cartPositon} w-[450px] space-y-4`}
          ref={cartRef}
        >
          <Cart
            country={country}
            participantDetailArray={[{ subjects: addedSubjects }]}
            amount={amount}
            setAmount={setAmount}
            participantName={studentDetails?.name}
          />
        </div>
      </div>
      <h1 className="text-2xl font-bold ">
        Participant : {studentDetails?.name}
      </h1>
      <div className="row" ref={subjectRef}>
        <div className="col-md-6">
          <div className="form-group">
            <div className="flex gap-4">
              <label className="">
                Select Subject<sup>*</sup>
              </label>
            </div>
            <div className="flex justify-start gap-12">
              {subjectsOptions
                ?.filter((sub) => !subjects?.includes(sub.value))
                .map((sub) => (
                  <div className="relative" key={sub.id}>
                    <div className="absolute top-[-2rem] right-[-18px]">
                      <Checkbox
                        checkId={sub.value}
                        checkName={sub.value}
                        checkValue={sub.value}
                        checked={addedSubjects?.includes(sub?.value)}
                        handleChange={() => {
                          setAddedSubjects((prev) =>
                            prev?.includes(sub.value)
                              ? prev?.filter((s) => s !== sub.value)
                              : [...prev, sub.value]
                          );
                          setError((prev) => ({ ...prev, subjects: "" }));
                        }}
                      />
                    </div>
                    <label
                      htmlFor={sub.value}
                      className="flex flex-col items-center justify-center gap-4 cursor-pointer"
                    >
                      <div
                        className={`w-[126px] h-[126px] rounded-2xl flex items-center justify-center subject-card
                                  ${
                                    addedSubjects?.includes(sub.value)
                                      ? "bg-[#F2B010] shadow-[0px_4px_0px_0px_#B88815]"
                                      : "bg-[#ECF0F1] shadow-[0px_4px_0px_0px_#A9A9A9] hover:bg-[#E5E5F1] "
                                  }
                                `}
                      >
                        <img src={sub.icon} alt={sub.label} />
                      </div>
                      <div className="font-bold text-[16px] text-[#34495E]">
                        {sub.label}
                      </div>
                    </label>
                  </div>
                ))}
            </div>
            {error?.subjects && (
              <div className="text-red-500 mb-2">{error?.subjects}</div>
            )}
          </div>
        </div>
      </div>
      <div className="my-8 imp-inst">
        <Step1Instructions
          checked={acknowledgeChecked}
          setChecked={setAcknowledgeChecked}
          acknowledgeErrorRef={acknowledgeErrorRef}
          error={error?.acknowledgement}
        />
      </div>
      <div
        className={`summary-card desktop-view w-[450px] z-40 right-[10%] -top-10 h-full absolute `}
      >
        <div
          className={`summary-card ${cartPositon} w-[450px] space-y-4`}
          ref={cartRef}
        >
          <Cart
            country={country}
            participantDetailArray={[{ subjects: addedSubjects }]}
            amount={amount}
            setAmount={setAmount}
            participantName={studentDetails?.name}
          />
          <Button
            loading={loading}
            text="Save & Pay"
            version="secondary"
            handleClick={handleSaveAndPay}
          />
        </div>
      </div>
    </div>
  );
};

export default AddSubjects;
