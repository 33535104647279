import React from "react";
import Instructions from "../auth/onboarding/Instructions";

const InstructionsLanding = () => {
  return (
    <section className="exam-date-section ribbon-right ribbon-left">
      <div className="w-full h-full flex justify-center">
        <div className="detail-section instructions-sectio mb-20 -mt-5">
          <Instructions />
        </div>
      </div>
    </section>
  );
};

export default InstructionsLanding;
