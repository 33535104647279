import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { constactFormEmail } from '../../constant/emailTemplates';
import envConfig from '../../config/envConfig';

const ContactForm = ({ contactFormRef }) => {
  const [loading, setLoading] = useState(false)
  const nameRef = useRef('')
  const emailRef = useRef('')
  const phoneRef = useRef('')
  const messageRef = useRef('')
  const formRef = useRef()

  const notifySuccess = () =>
    toast.success("Thank you! We will get back to you shortly.");
  const notifyError = () =>
    toast.error("Something went wrong. Please try again.");

  const handleContact = async (e) => {
    e.preventDefault();
    setLoading(true);
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const message = messageRef.current.value;
    const subject = `${envConfig.appName} - Contact`;
    const emailTemplate = constactFormEmail(name, email, phone, message);

    const emailApi = envConfig.emailApi;
    const submitData = JSON.stringify([
      envConfig.appEmail,
      subject,
      emailTemplate,
    ]);
    try {
      const response = await axios.post(emailApi, submitData);
      if (response.data.message === "success") {
        notifySuccess();
        formRef.current.reset();
      } else {
        notifyError();
      }
    } catch (error) {
      console.log(error);
      notifyError();
    }
    setLoading(false)
  }
  useEffect(() => {
    contactFormRef(formRef.current);
  }, [contactFormRef]);

  return (
    <section className="home-form" id="contact">
      <ToastContainer />
      <div
        className="container"
        data-aos="fade-up"
        data-aos-delay="150"
        data-aos-duration="500"
      >
        <div className="row">
          <div className="col-sm-12 col-md-12 d-md-none d-block">
            <h3 data-aos="fade-up" data-aos-delay="150" data-aos-duration="500">
              Contact Us
            </h3>
          </div>
          <div className="col-md-7">
            <div className="img-box">
              <img src="/img/new-home/download-app.png" alt="contact-us" />
            </div>
            {/* <div className="contact-detail">
              <ul>
                <li><img src="/img/new-home/location.png" alt="location" /> <span>Noida, India</span></li>
                <li><img src="/img/new-home/tel.png" alt="telphone" /> <span>+91-9999999999</span></li>
              </ul>
            </div> */}
          </div>
          {/* col-md-6 */}
          <div className="col-md-5">
            <form className="form row" onSubmit={handleContact} ref={formRef}>
              <div className="col-sm-12 col-md-12 d-none d-md-block">
                <h3
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="500"
                >
                  Contact Us
                </h3>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="name">
                    Name<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactUs-name"
                    ref={nameRef}
                    aria-describedby="user-name"
                    placeholder="eg. John Doe"
                    required
                  />
                  <span className="error">Please enter the required field</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="email">
                    Email<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactUs-email"
                    ref={emailRef}
                    aria-describedby="email-adress"
                    placeholder="eg.john@gmail.com"
                    required
                  />
                  <span className="error">Please enter the required field</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="phone">
                    Phone Number<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactUs-phone"
                    ref={phoneRef}
                    aria-describedby="phone-number"
                    placeholder="eg. xxxxxxxxxx"
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    type="textarea"
                    className="form-control"
                    id="contactUs-message"
                    ref={messageRef}
                    aria-describedby="message-detail"
                    placeholder="Type here"
                  ></textarea>
                  <span className="error">Please enter the required field</span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group center-btn">
                  <button
                    type="submit"
                    id="contactUsSubmitBtn"
                    className="btn blue-btn"
                  >
                    {loading ? "Please wait" : "Submit"}
                  </button >
                </div >
              </div >
            </form >
          </div >
        </div >
        {/* row */}
      </div >


      <div className="qr-wrapper">
        <div className="container qr-container">
          <div className="text">For practice, download the app by scanning QR code. <img src="/img/new-home/white-arrow.png" alt="arrow" /></div>
          <div className="qr-box"><img src="/img/new-home/qr-scanner.png" alt="qr-scanner" /></div>
        </div>
      </div>

      {/* qr-wrapper-mb */}
      <section className="qr-wrapper-mb">
        <div className='qr-img'>
          <img className="toy-img" src="/img/new-home/g10.png" alt="g10" />
          <img className="scanner" src="/img/new-home/qr-scanner.png" alt="qr-scanner" />
        </div>
        <p>For practice, <a target="_blank" href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice">download the app</a> by scanning QR code.</p>
      </section>
    </section >
  )
}


export default ContactForm;
