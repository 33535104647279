import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import CustomLink from './customLink';

const Footer = ({ placement }) => {
  const navigate = useNavigate()
  const clickToScroll = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute('href');
    if (placement === 'landingPage') {
      const targetElement = document.querySelector(targetId);
      const offset = targetElement.offsetTop;
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
    } else {
      navigate(`/${targetId}`)

    }
  }

  return (
    <div className="footer olympiad-footer">
      <img className='footer-bg' src="/img/new-home/footer-bg.png" alt="footer background" />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <img className="logo" src="/img/logo.png" alt="logo" />
            <ul className="social-list">
              <li>
                <a target="_blank" rel='noreferrer' href="https://www.facebook.com/profile.php?id=100094743883639">
                  <img width="24" height="24" src="/img/social/fb.png" alt="facebook" />
                </a>
              </li>
              <li>
                <a target="_blank" rel='noreferrer' href="https://www.instagram.com/olympiadpracticeapp?igshid=YzVkODRmOTdmMw==">
                  <img width="24" height="24" src="/img/social/intsa.png" alt="instagram" />
                </a>
              </li>
              <li>
                <a target="_blank" rel='noreferrer' href="https://twitter.com/olympiad_p">
                  <img width="24" height="24" src="/img/social/tw.png" alt="twitter" />
                </a>
              </li>
              <li>
                <a target="_blank" rel='noreferrer' href="https://youtube.com/@OlympiadPracticeApp?feature=shared">
                  <img width="24" height="24" src="/img/social/youtube.png" alt="youtube" />
                </a>
              </li>
              <li>
                <a target="_blank" rel='noreferrer' href="https://whatsapp.com/channel/0029Va50kJ33rZZbb9X3za1q">
                  <img width="24" height="24" src="/img/social/whatapp.png" alt="Whatsapp" />
                </a>
              </li>
              <li>
                <a target="_blank" rel='noreferrer' href="mailto:hello@practido.com">
                  <img width="24" height="24" src="/img/social/email.png" alt="email" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-12">
            <ul className="menu-list">
              <li className="menu__item mega-menu-item">
                <CustomLink
                  to="/about"
                  className="menu__link"
                >
                  About Us
                </CustomLink>
              </li>
              <li className="menu__item mega-menu-item">
                <CustomLink
                  to="/privacy"
                  className="menu__link"
                >
                  Privacy Policy
                </CustomLink>
              </li>
              <li className="menu__item mega-menu-item">
                <CustomLink
                  to="/terms"
                  className="menu__link"
                >
                  Terms & Conditions
                </CustomLink>
              </li>
              <li className="menu__item mega-menu-item">
                <CustomLink
                  to="/contactus"
                  className="menu__link"
                >
                  Contact Us
                </CustomLink>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-12">
            <p>Download now and give yourself an edge over other participants at Olympiad!</p>
            <div className="btn-space">
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice" type="button" className="btn btn-link">
                <img src="/img/student-home/google-play.png" alt="google-play" />
              </a>
            </div>
            <p className='copyright'>Copyright © 2024 Practido and Naskay Solutions All rights reserved</p>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Footer