import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaGreaterThan } from "react-icons/fa6";
import { getMetadata, ref } from "firebase/storage";
import { storage } from "../../../config/firebaseConfig";

const StudentCard = ({ student }) => {
  const { id, name, imageUrl, className, subjects, idProofUrl } = student;
  const [fileMeta, setFileMeta] = useState();

  useEffect(() => {
    const fileUrl = idProofUrl;
    if (fileUrl) {
      const storageRef = ref(storage, fileUrl);
      getMetadata(storageRef)
        .then((metadata) => {
          setFileMeta(metadata);
        })
        .catch((error) => {
          console.error(`Error getting file metadata: ${error}`);
        });
    }
  }, [idProofUrl]);
  return (
    <div
      className="bg-white p-6 rounded-xl mb-4 w-[320px]"
      style={{ boxShadow: "0px 3px 0px #DFDFDF" }}
    >
      <div className="flex gap-3 border-b-2 border-gray-300 pb-3">
        <img
          src={imageUrl || "/img/profile.png"}
          alt=""
          className="w-16 h-16 rounded-xl"
        />
        <div className="flex flex-col justify-evenly text-base">
          <strong className="capitalize">{name}</strong>
          <span>
            Class : <strong>{className}</strong>
            {className === 1
              ? "st"
              : className === 2
                ? "nd"
                : className === 3
                  ? "rd"
                  : "th"}
          </span>
        </div>
      </div>
      <div className="pt-4 text-base space-y-2">
        <div className="flex flex-col">
          <strong>Subject : </strong>
          <span className="capitalize">
            {subjects?.map((sub) => sub.subject)?.join(", ")}
          </span>
        </div>
        <div className="flex flex-col">
          <strong>Student’s Id proof : </strong>
          {idProofUrl ? (
            <a
              href={idProofUrl}
              target="_blank"
              rel="noreferrer"
              className="text-[#0080FF] font-semibold"
            >
              {fileMeta?.name + "." + fileMeta?.contentType.split("/")[1] ||
                "Not Uploaded"}
            </a>
          ) : (
            <span>Id Proof Not Uploaded</span>
          )}
        </div>
        <Link to={`${id}`} className="flex gap-2 items-center text-[#009DFF] font-bold">
          View Details <FaGreaterThan className="w-3 h-3" />
        </Link>
      </div>
    </div>
  );
};

export default StudentCard;
