export const schoolContactRegisterFormFields = [
  {
    name: "contactPersonName",
    label: "Contact Person Name",
    type: "text",
    required: true,
    placeholder: "eg. : Sam Deo",
    colSpan: 2,
  },
  {
    name: "contactPersonEmail",
    label: "Contact Person Email Id",
    type: "email",
    required: true,
    placeholder: "eg. : example123@mail.con",
    colSpan: 2,
  },
  {
    name: "contactPersonPhone",
    label: "Contact Person Mobile Number",
    type: "tel",
    required: true,
    placeholder: "eg. : 1234567890",
    colSpan: 2,
  },
];
export const schoolPrincipalRegisterFormFields = [
  {
    name: "principalName",
    label: "Principal Name",
    type: "text",
    required: true,
    placeholder: "eg. : John Doe",
    colSpan: 2,
  },
  {
    name: "principalEmail",
    label: "Principal Email Id",
    type: "email",
    required: true,
    placeholder: "eg. : principal123@mail.con",
    colSpan: 2,
  },
  {
    name: "principalPhone",
    label: "Principal Contact No.",
    type: "tel",
    required: true,
    placeholder: "eg. : 1234567890",
    colSpan: 2,
  },
];
export const schoolRegisterFormFields = [
  {
    name: "schoolName",
    label: "School Name",
    type: "text",
    required: true,
    placeholder: "eg. : National Public School",
    colSpan: 2,
  },
  {
    name: "schoolAddress",
    label: "School Address",
    type: "text",
    required: true,
    placeholder: "eg. : #123, Main Road",
    colSpan: 2,
  },
  {
    name: "country",
    label: "Country",
    type: "text",
    required: true,
    placeholder: "eg. : India",
    colSpan: 1,
  },
  {
    name: "state",
    label: "State",
    type: "text",
    required: true,
    placeholder: "eg. : New Delhi",
    colSpan: 1,
  },
  {
    name: "city",
    label: "City",
    type: "text",
    required: true,
    placeholder: "eg. : New Delhi",
    colSpan: 1,
  },
  {
    name: "pincode",
    label: "Pincode",
    type: "text",
    required: true,
    placeholder: "eg. : 110001",
    colSpan: 1,
  },
  {
    name: "schoolEmail",
    label: "School Email Id",
    type: "email",
    required: true,
    placeholder: "eg. : school123@mail.con",
    colSpan: 2,
  },
  {
    name: "schoolPhone",
    label: "School Phone Number",
    type: "tel",
    required: true,
    placeholder: "eg. : 1234567890",
    colSpan: 2,
  },
];
