import React, { useState } from "react";

const AccordionPage = () => {
  const [active, setActive] = useState(1);
  const handleActive = (index) => {
    setActive((prev) => (prev === index ? 0 : index));
  };

  const faqDataFull = [
    {
      id: "One",
      ariaExpanded: "true",
      title: "Who is eligible to sit for the Practido Online Olympiad?",
      content:
        "The Practido Online Olympiad is open to all students from Classes 1 to 8. It is designed to be inclusive, allowing participation from anywhere with a reliable internet connection.",
    },
    {
      id: "Two",
      ariaExpanded: "false",
      title: "What is the mode of the exam?",
      content:
        "The Practido Online Olympiad is conducted entirely online. Participants will need a laptop, or desktop with a web camera, microphone, and a stable internet connection to take the exam.",
    },
    {
      id: "Three",
      ariaExpanded: "false",
      title: "Can a school register for the Practido Online Olympiad?",
      content:
        "Yes, schools can register their students collectively for the Practido Online Olympiad. There is a minimum requirement of 50 students from a school to qualify for certain benefits.",
    },
    {
      id: "Four",
      ariaExpanded: "false",
      title: "Can I register individually for the Practido Online Olympiad?",
      content:
        "Yes, individual students can register for the Practido Online Olympiad through our official website or app, without the need for school registration.",
    },
    {
      id: "Five",
      ariaExpanded: "false",
      title: "Which subjects are covered under the Practido Online Olympiad?",
      content:
        "The Olympiad covers three key subjects: English, Maths, and Science.",
    },
    {
      id: "Six",
      ariaExpanded: "false",
      title: "How can a student of Class 1 (2024-25) appear for online exams?",
      content:
        "A student of Class 1 can participate with the help of a guardian to ensure they understand the process and have the necessary equipment set up, such as a computer with internet access.",
    },
    {
      id: "Seven",
      ariaExpanded: "false",
      title: "What is the Free Trial?",
      content:
        "We will provide a demo test before the actual Olympiad so students can familiarize themselves with the exam format and interface.",
    },
    {
      id: "Eight",
      ariaExpanded: "false",
      title: "What is the exam fee for each subject?",
      content:
        "The exam fee is Rs. 99 for one subject and Rs. 199 for all three subjects for Indian students. For international students, the fee is $8 for one subject and $20 for all three subjects.",
    },
    {
      id: "Nine",
      ariaExpanded: "false",
      title: "What is the refund policy?",
      content:
        "The fee is non-refundable once paid. We recommend double-checking all details before completing the payment process.",
    },
    {
      id: "ten",
      ariaExpanded: "false",
      title: "What is the venue for online and pen/paper exams?",
      content:
        "The Practido Online Olympiad is conducted entirely online, so there is no physical venue required. Participants can take the exam from the comfort of their homes.",
    },
    {
      id: "Eleven",
      ariaExpanded: "false",
      title:
        "What is the syllabus for all the subjects of the Practido Online Olympiad?",
      content:
        "The syllabus for each subject is aligned with the standard curriculum for the respective grade levels. It covers essential topics in English, Maths, and Science as outlined in school textbooks.",
    },
    {
      id: "Twelve",
      ariaExpanded: "false",
      title: "What is the structure and pattern of the examination?",
      content:
        "Participants must attempt as many questions as possible and provide the correct answers within 50 minutes to become a winner. Please note that participants cannot revisit previous questions once they have moved on to the next one.",
    },
    {
      id: "Thirteen",
      ariaExpanded: "false",
      title:
        "What will be the ranking criteria in the Practido Online Olympiad?",
      content:
        "Rankings are based on the number of correct answers and the time taken to complete the test. Participants with the highest number of correct answers in the shortest time will rank the highest.",
    },
    {
      id: "Fourteen",
      ariaExpanded: "false",
      title:
        "The results are declared. I see percentage and percentile being given, but the ranking is not mentioned.",
      content:
        "Rankings are typically provided after the initial results. Please check the official website or your registered email for detailed ranking information.",
    },
    {
      id: "Fifteen",
      ariaExpanded: "false",
      title: "After the payment, what should be done next?",
      content:
        "After making the payment, you will receive a confirmation email with details on how to access the test. Make sure to check your email and follow the instructions to complete your registration.",
    },
    {
      id: "Sixteen",
      ariaExpanded: "false",
      title:
        " I want to change my booked slot time for the exam. What should I do?",
      content:
        "To change your exam slot, please contact our support team at olympiadpractice@naskay.in with your registration details. We will assist you in rescheduling.",
    },
    {
      id: "Seventeen",
      ariaExpanded: "false",
      title: "What is the procedure to take the Practido Online Olympiad exam?",
      content:
        "Register for the exam, pay the applicable fees, and receive your exam credentials. On the exam day, log in 15 minutes before the scheduled time, ensure your equipment is set up correctly, and follow the on-screen instructions to complete the exam.",
    },
    {
      id: "Eighteen",
      ariaExpanded: "false",
      title: "When will the answer keys for the exams be available?",
      content:
        "Answer keys will be available a few days after the exam. They will be accessible through your registered account or sent via email.",
    },
    {
      id: "Nineteen",
      ariaExpanded: "false",
      title:
        "What if I find a mistake in the questions and/or answers in the Answer Keys?",
      content:
        "If you believe there is an error, please contact us at olympiadpractice@naskay.in with details of the issue. We will review and address any valid concerns.",
    },

    {
      id: "Twenty",
      ariaExpanded: "false",
      title: "Do I need to pay if I challenge any question?",
      content:
        "No, there is no fee for challenging a question. You can submit your challenge, and it will be reviewed by our academic team.",
    },
    {
      id: "Twenty-one",
      ariaExpanded: "false",
      title:
        "What are the benefits for schools participating in the Practido Online Olympiad?",
      content:
        "Schools participating in the Practido Online Olympiad enjoy the following benefits: Recognition Awards: Schools receive a Trophy for Academic Excellence. Top 5 Principals, and Teachers are awarded cash prizes and appreciation awards. Participation Requirement: A minimum of 50 students from a school must participate to qualify for these benefits.",
    },
    {
      id: "Twenty-two",
      ariaExpanded: "false",
      title: "How do I enable the camera for all the tests?",
      content:
        "To ensure your camera is properly set up, follow the instructions for your specific browser: Google Chrome: https://support.google.com/chrome/answer/2693767 Firefox Mozilla: https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions Microsoft Edge: https://answers.microsoft.com/en-us/edge/forum/all/how-do-i-turn-on-camera-in-microsoft-edge/408693e9-02a9-4503-84ab-382f62912adf Safari on Mac: https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac",
    },
    {
      id: "Twenty-three",
      ariaExpanded: "false",
      title: "Why am I not receiving any emails from Practido Online Olympiad?",
      content:
        "Check your spam or junk folder for our emails. Ensure that you have provided the correct email address during registration. If you still have issues, contact us at olympiadpractice@naskay.in.",
    },
    {
      id: "Twenty-Four",
      ariaExpanded: "false",
      title: "My question is not listed. How do I contact you?",
      content:
        "If you have additional questions, please email us at olympiadpractice@naskay.in, and we will be more than happy to assist you.",
    },
  ];
  return (
    <div className="accordion" id="accordiondata">
      {faqDataFull?.map(({ id, ariaExpanded, title, content }, index) => (
        <div
          className={`card ${active === index + 1 ? "active" : ""}`}
          key={id}
        >
          <div className="card-header" id={`heading${id}`}>
            <button
              className={`btn-link btn-block text-left ${
                ariaExpanded !== "true" ? "collapsed" : ""
              }`}
              type="button"
              data-toggle="collapse"
              data-target={`#collapse${id}`}
              aria-expanded={ariaExpanded}
              aria-controls={`collapse${id}`}
              onClick={() => handleActive(index + 1)}
            >
              {title}
            </button>
          </div>
          <div
            id={`collapse${id}`}
            className={`collapse ${ariaExpanded === "true" ? "show" : ""}`}
            aria-labelledby={`heading${id}`}
            data-parent="#accordiondata"
          >
            <div className="card-body">{content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordionPage;
