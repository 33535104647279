import React, { useState, useCallback, useEffect } from "react";
import { storage } from "../../../config/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";

const FileUpload = ({
  updateUrl,
  participantModuleIndex,
  openImageCapture,
  participantName,
  uploadedImageUrl,
  handleDelete,
  updateRef,
  idProofUploading,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (idProofUploading) {
      // Start the progress bar when loading is true
      interval = setInterval(() => {
        setProgress((prevProgress) => (prevProgress + 5) % 101);
      }, 100);
    } else {
      setProgress(0);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [idProofUploading]);

  const uploadFile = async (file) => {
    const uniqueId = (Math.random() + 1).toString(36).substring(4);
    if (!file) return;
    let isCancelled = false;
    try {
      setLoading(true);
      const fileRef = ref(
        storage,
        `participant_files/${participantName?.replace(
          " ",
          "_"
        )}_idProof_${uniqueId}`
      );
      updateRef("idProof", fileRef, participantModuleIndex);

      //upload progress
      const uploadTask = uploadBytesResumable(fileRef, file);

      // Function to cancel the upload
      const cancelUpload = () => {
        isCancelled = true;
        uploadTask.cancel();
        setLoading(false);
        setProgress(0);
        setSelectedFile(null);
        console.log("Uploading stopped");
      };
      // Expose cancelUpload function for external use
      window.cancelUpload = cancelUpload;

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          if (isCancelled) return; // Check if upload is cancelled
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          if (isCancelled) return; // Check if upload is cancelled
          console.error(error);
          setError(error.message);
          setLoading(false);
        },

        async () => {
          if (isCancelled) return; // Check if upload is cancelled
          // Upload completed successfully
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          updateUrl("idProof", url, participantModuleIndex);
          setLoading(false);
          setProgress(0);
        }
      );
    } catch (error) {
      console.error(error);
      setError(error.message);
      setLoading(false);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
      uploadFile(acceptedFiles[0]);
    },
    [participantName]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.ms-powerpoint": [],
    },
  });

  return (
    <div>
      {selectedFile || uploadedImageUrl || idProofUploading ? (
        <div
          className={`relative bg-white flex flex-col col-md-5 p-3 rounded-[10px] px-4 overflow-hidden ${
            loading || idProofUploading
              ? "border-[#ccc] border-[2px]"
              : "border-[#F39C12] border-[2px] "
          }`}
        >
          <div className="flex items-center justify-between">
            <p>
              {selectedFile
                ? selectedFile.name
                : uploadedImageUrl || idProofUploading
                ? "IdProof Image"
                : ""}
            </p>
            <div>
              {(loading || idProofUploading) && (
                <div className="text-[#999] text-sm font-bold">Uploading</div>
              )}
            </div>
            {loading ? (
              <div
                onClick={() => window.cancelUpload()}
                className="cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 text-[#999] hover:text-[#4488ff] hover:scale-125"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            ) : (
              <div
                onClick={() => {
                  handleDelete("idProof", participantModuleIndex);
                  setSelectedFile();
                }}
                className="cursor-pointer"
              >
                <MdDelete className="w-6 h-6 text-[#ff1800]" />
              </div>
            )}
          </div>
          {(loading || idProofUploading) && (
            <div className="absolute bottom-0 left-0 w-full h-[3px]">
              <div
                style={{ width: `${progress}%` }}
                className="h-full bg-red-500 transition-width duration-300 ease-in-out"
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <span className="text-[#34495E]/70 font-bold">
            Upload the Participant's Aadhaar Card, Birth Certificate, or any
            other Valid ID Card.
          </span>
          <div className="flex flex-col md:flex-row gap-1 md:gap-6 items-center mt-2">
            <div className="w-full md:w-60 h-36 bg-white border-[3px] border-dashed border-gray-300 rounded-xl p-3 cursor-pointer">
              <div {...getRootProps({ className: "dropzone w-full h-full" })}>
                <input {...getInputProps()} />
                {selectedFile ? (
                  <div className="flex flex-col items-center justify-center w-full h-full text-[#676767] font-bold text-lg">
                    {selectedFile.name}
                  </div>
                ) : (
                  <div className="w-full h-full flex flex-col items-center justify-between">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1}
                      stroke="currentColor"
                      className="w-10 text-[#E74C3C]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                      />
                    </svg>
                    <p className="text-[12px] text-[#34495E] font-bold">
                      Drag & drop files or
                      <span className="text-[#E74C3C] underline ml-1">
                        Browse
                      </span>
                    </p>
                    <p className="text-[11px] text-[#676767] text-center">
                      Supported formates: JPEG, PNG, PDF, Word, PPT
                    </p>
                  </div>
                )}
              </div>
            </div>

            <span className="text-[#34495E]/70 font-bold">OR</span>
            <div
              className="relative w-full md:w-60 h-36 bg-white border-[3px] border-dashed border-gray-300 rounded-xl p-3 cursor-pointer"
              onClick={() =>
                openImageCapture(participantModuleIndex, "idProof")
              }
            >
              <div className="w-full h-full flex flex-col items-center justify-between">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1}
                  stroke="currentColor"
                  className="w-10 text-[#E74C3C]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                  />
                </svg>

                <p className="text-[12px] text-[#34495E] font-bold">
                  Take a Photo
                </p>
                <p className="text-[11px] text-[#676767] text-center">
                  Capture a photo of your student ID for verification.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="text-[#f00] text-sm ">{error}</div>
    </div>
  );
};

export default FileUpload;
