import React, { useState, useCallback } from "react";
import { storage } from "../../../../config/firebaseConfig";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useDropzone } from "react-dropzone";

const UploadLogo = ({
  fileName,
  url,
  setUrl,
  fileRef,
  setFileRef,
  loading,
  setLoading,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    uploadFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
    },
  });

  const uploadFile = async (file) => {
    const uniqueId = (Math.random() + 1).toString(36).substring(4);
    if (!file) return;
    let isCancelled = false;
    try {
      setLoading((prev) => ({ ...prev, logoUploading: true }));
      const fileRef = ref(storage, `${fileName}_${uniqueId}`);
      setFileRef(fileRef);

      //upload progress
      const uploadTask = uploadBytesResumable(fileRef, file);

      // Function to cancel the upload
      const cancelUpload = () => {
        isCancelled = true;
        uploadTask.cancel();
        setLoading((prev) => ({ ...prev, logoUploading: false }));
        setProgress(0);
        setSelectedFile(null);
        console.log("Uploading stopped");
      };
      // Expose cancelUpload function for external use
      window.cancelUpload = cancelUpload;

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          if (isCancelled) return; // Check if upload is cancelled
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          if (isCancelled) return; // Check if upload is cancelled
          console.error(error);
          setError(error.message);
          setLoading((prev) => ({ ...prev, logoUploading: false }));
        },

        async () => {
          if (isCancelled) return; // Check if upload is cancelled
          // Upload completed successfully
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          setUrl(url);
          setLoading((prev) => ({ ...prev, logoUploading: false }));
          setProgress(0);
        }
      );
    } catch (error) {
      console.error(error);
      setError(error.message);
      setLoading((prev) => ({ ...prev, logoUploading: false }));
    }
  };

  const handleDelete = async () => {
    try {
      await deleteObject(fileRef);
      setUrl("");
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      {selectedFile || url ? (
        <div
          className={`relative w-full bg-white flex p-4 rounded-[10px] px-4 overflow-hidden ${
            loading?.logoUploading
              ? "border-[#ccc] border-[2px]"
              : "border-[#F39C12] border-[2px] "
          }`}
        >
          <div className="flex w-full items-center justify-between">
            <p>{selectedFile ? selectedFile.name : ""}</p>
            <div>
              {loading?.logoUploading && (
                <div className="text-[#999] text-sm font-bold">Uploading</div>
              )}
            </div>
            {loading?.logoUploading ? (
              <div
                onClick={() => window.cancelUpload()}
                className="cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 text-[#999] hover:text-[#4488ff] hover:scale-125"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            ) : (
              <div
                onClick={() => {
                  handleDelete();
                  setSelectedFile();
                }}
                className="cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-5 text-[#ff1800]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </div>
            )}
          </div>
          {loading?.logoUploading && (
            <div className="absolute bottom-0 left-0 w-full h-[3px]">
              <div
                style={{ width: `${progress}%` }}
                className="h-full bg-red-500 transition-width duration-300 ease-in-out"
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="w-full school-logo rounded-xl">
            <div {...getRootProps({ className: "dropzone w-full h-full" })}>
              <input {...getInputProps()} />
              {selectedFile ? (
                <div className="flex flex-col items-center justify-center w-full h-full text-[#676767] font-bold text-lg">
                  {selectedFile.name}
                </div>
              ) : (
                <div className="w-full h-full flex flex-col items-center justify-center gap-2 cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    className="w-10 text-[#E74C3C]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                    />
                  </svg>
                  <p className="bold big">
                    Drag & drop files or <span>Browse</span>
                  </p>
                  <p>Supported formates: JPEG, PNG</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className="text-[#f00] text-sm ">{error}</div>
    </>
  );
};

export default UploadLogo;
