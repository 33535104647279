import React from "react";


const OlympiadExams = () => {

    return (
        <section className="resource-section olympiad-exams">
            <div className="container aos-animate" data-aos="fade-up">
                <div className="head">
                    <h3>
                        The Most Innovative Online Olympiad Exams
                        <span>
                            Conducted for grades 1 to 8<br />
                            (INR 99 per subject per participant or INR 199 all three subjects per participants)
                        </span>
                    </h3>
                </div>
                <div className="row">
                    <div className="col-data col-md-12 col-lg-4">
                        <div className="img-box">
                            <img src="/img/reg/innovative-1.png" alt="pmo" />
                        </div>
                        <h4>PMO<br />
                            (Practido Mathematics Olympiad)</h4>
                        <p><span className="bold">Subject Covered:</span> Mathematics</p>
                        <p><span className="bold">Type of Questions:</span> Multiple Choice Questions (MCQs)</p>
                        <p><span className="bold">Mode of Registration:</span> Online</p>
                        <p><span className="bold">Mode of Examination:</span> Online</p>
                        <p><span className="bold">Class:</span> 1 to 8</p>
                        <p><span className="bold">Price:</span> Rs. 99 (for India), $5 per subject per participant $10 all three subjects per participant (for international)</p>
                        <div className="btn-inline">
                            <button type="button" className="btn btn-secondary">Apply</button>
                            <button type="button" className="btn link-btn">Syllabus</button>
                        </div>
                    </div>
                    <div className="col-data col-md-12 col-lg-4">
                        <div className="img-box">
                            <img src="/img/reg/innovative-2.png" alt="pso" />
                        </div>
                        <h4>PSO<br />
                            (Practido Science Olympiad)</h4>
                        <p><span className="bold">Subject Covered:</span>Science</p>
                        <p><span className="bold">Type of Questions:</span> Multiple Choice Questions (MCQs)</p>
                        <p><span className="bold">Mode of Registration:</span> Online</p>
                        <p><span className="bold">Mode of Examination:</span> Online</p>
                        <p><span className="bold">Class:</span> 1 to 8</p>
                        <p><span className="bold">Price:</span> Rs. 99 (for India), $5 per subject per participant $10 all three subjects per participant (for international)</p>
                        <div className="btn-inline">
                            <button type="button" className="btn btn-secondary">Apply</button>
                            <button type="button" className="btn link-btn">Syllabus</button>
                        </div>
                    </div>
                    <div className="col-data col-md-12 col-lg-4">
                        <div className="img-box">
                            <img src="/img/reg/innovative-3.png" alt="peo" />
                        </div>
                        <h4>PEO<br />
                            (Practido English Olympiad)</h4>
                        <p><span className="bold">Subject Covered:</span>English</p>
                        <p><span className="bold">Type of Questions:</span> Multiple Choice Questions (MCQs)</p>
                        <p><span className="bold">Mode of Registration:</span> Online</p>
                        <p><span className="bold">Mode of Examination:</span> Online</p>
                        <p><span className="bold">Class:</span> 1 to 8</p>
                        <p><span className="bold">Price:</span> Rs. 99 (for India), $5 per subject per participant $10 all three subjects per participant (for international)</p>
                        <div className="btn-inline">
                            <button type="button" className="btn btn-secondary">Apply</button>
                            <button type="button" className="btn link-btn">Syllabus</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default OlympiadExams