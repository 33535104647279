import React from "react";
import { Card, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

const StudentsTable = ({ students, searchQuery }) => {
  const navigate = useNavigate();
  const tableHead = [
    "S.No.",
    "Enroll. No.",
    "StudentName",
    "Parent's Name",
    "Class",
    "Mobile No.",
    "Email",
    "Subjects",
    "Exam Date",
    "Action",
  ];
  return (
    <>
      <Card className="w-full table-card">
        <table className="w-full h-full min-w-max table-auto text-left">
          <thead className="">
            <tr>
              {tableHead.map((head, index) => (
                <th
                  key={index}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {students?.filter((student) => {
              return (
                student.name
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                student.enrollmentNo
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                student.parent.name
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                student.parent.email
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              );
            }).length === 0 ? (
              <tr className="text-center w-full">
                <td className="text-xl font-bold text-gray-500" colSpan={10}>
                  <div className="my-3">No Match Found</div>
                </td>
              </tr>
            ) : (
              students
                ?.filter((student) => {
                  return (
                    student.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    student.enrollmentNo
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    student.parent.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    student.parent.email
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  );
                })
                ?.map(
                  (
                    { id, enrollmentNo, name, className, subjects, parent },
                    index
                  ) => (
                    <>
                      <tr key={index} className="even:bg-blue-gray-50/50">
                        <td className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {index + 1}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {enrollmentNo}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal capitalize"
                          >
                            {name}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal capitalize"
                          >
                            {parent?.name}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {className}
                            {className === 1
                              ? "st"
                              : className === 2
                              ? "nd"
                              : className === 3
                              ? "rd"
                              : "th"}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {parent?.phone}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {parent?.email}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal capitalize"
                          >
                            {subjects?.map((sub) => sub?.subject)?.join(", ")}
                          </Typography>
                        </td>
                        <td className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            --
                          </Typography>
                        </td>
                        <td className="h-full flex gap-3 justify-center items-center mr-4">
                          <span className="font-semibold">Result</span>
                          <span
                            className="font-bold cursor-pointer text-blue-500 hover:text-blue-600 hover:underline"
                            onClick={() => navigate(`${id}`)}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    </>
                  )
                )
            )}
          </tbody>
        </table>
      </Card>
    </>
  );
};

export default StudentsTable;
