import envConfig from "../../config/envConfig";

export const parentRegisterSuccess = ({ parentName, userId }) => {
  const subject =
    "Welcome to Practido Online Olympiad 2024! Your Registration is Successful 🎉";
  const emailBody = `
Dear <strong>${parentName}</strong>,

<p>
We are thrilled to welcome you and your child to the Practido community! <br>
Your registration was successful, and we're excited to support your child's academic journey.
</p>
<p>
Important Information:<br>
User ID: <strong>${userId}</strong><br>
Support: If you have any questions or need assistance, our support team is here to help. 
You can reach us at <strong>${envConfig.supportEmail}</strong>.
</p>

<p>
We're committed to providing the best educational experience for your child. <br>
Stay tuned for updates, tips, and exclusive content to make the most out of Practido Online Olympiad 2024.<br>
Thank you for choosing us. Together, let's unlock your child's full potential!
</p>

<p>
Best regards,<br>
Team Practido.
</p>

<p>
Follow us on social media:<br>
Facebook : ${envConfig.facebookLink}<br>
Twitter : ${envConfig.twitterLink}<br>
Instagram : ${envConfig.instagramLink}
</p>
<p>
Download the App:<br>
Google Play : ${envConfig.googlePlayLink}
</p>
  `;

  return { subject, emailBody };
};
